import React, { useState, useEffect } from 'react'
import useWindowSize from '../../hooks/useWindowSize'
import { Modal, Button } from 'react-bootstrap'
import { BsEnvelopeFill } from 'react-icons/bs'

// import styles from './ScreenSizeAlert.module.scss'

function ScreenSuccess({showModal, setShowModal, showErrorModal, setShowErrorModal}) {

  const handleClose = () => {
    setShowModal(false)
    setShowErrorModal(false)
  }


  return (
    <Modal
      className="raw-modal"
      show={showModal||showErrorModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">   
          <span role="img" aria-label="Party icon">
            {showModal ? '🎉' : '❌' }
          </span>{' '}
          {showModal ? 'Great!' : '!Error'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="big">
        {showModal 
            ? 
        'The chart has been successfully added to your library' 
            : 
        'Something want wrong'}
        </p>
        {/* <p>
          Resize your browser window or{' '}
          <a href="mailto:?subject=Visit+RAWGraphs+2.0&body=Hello%21%0D%0APlease+do+not+forget+to+take+a+look+at+the+new+version+of+RAWGraphs%21%0D%0A%0D%0AVisit%3A+https%3A%2F%2Fdev.rawgraphs.io%2F%0D%0A%0D%0ASee+you+later%2C%0D%0AThe+RAWGraphs+Team">
            <BsEnvelopeFill /> send yourself a reminder
          </a>{' '}
          to come back at a better time.
        </p> */}
        <p> {showModal 
            ? 'You can add the chart to your insight from your dashboard.' 
            : 'Please navigate from yor dashboard again or download the chart.'}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Got it!
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ScreenSuccess
