import React, { useCallback, useState } from 'react'
import queryString from 'query-string'
import { InputGroup, DropdownButton, Dropdown } from 'react-bootstrap'
import { onChartExported } from '../../gaEvents'
import axios from 'axios';
import { Spinner } from 'react-bootstrap'
import ScreenSuccess from '../ScreenSizeAlert/ScreenSuccess'
function downloadBlob(url, filename) {
  // Create a new anchor element
  const a = document.createElement('a')
  a.href = url
  a.download = filename || 'download'
  a.click()
  return a
}

export default function Exporter({ rawViz, exportProject }) {

  const values = queryString.parse(window.location.search)

  const [imageUrl, setImageUrl] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);

  const downloadSvg = useCallback(
    (filename) => {
      var svgString = new XMLSerializer().serializeToString(
        rawViz._node.firstChild
      )
      var DOMURL = window.URL || window.webkitURL || window
      var svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
      var url = DOMURL.createObjectURL(svg)
      downloadBlob(url, filename)
      DOMURL.revokeObjectURL(svg)
    },
    [rawViz]
  )

  const downloadImage = useCallback(
    (format, filename) => {
      var svgString = new XMLSerializer().serializeToString(
        rawViz._node.firstChild
      )
      var DOMURL = window.URL || window.webkitURL || window
      var svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
      var url = DOMURL.createObjectURL(svg)
      var canvas = document.createElement('canvas')
      canvas.height = rawViz._node.firstChild.clientHeight
      canvas.width = rawViz._node.firstChild.clientWidth
      var ctx = canvas.getContext('2d')
      var img = new Image()
      img.onload = function () {
        ctx.drawImage(img, 0, 0)
        var dataUrl = canvas.toDataURL(format)
        downloadBlob(dataUrl, filename)
        DOMURL.revokeObjectURL(svg)
      }
      img.src = url
    },
    [rawViz]
  )

  const exportImageToLibrary = useCallback(
    (format, filename) => {
      setLoading(true)
      var svgString = new XMLSerializer().serializeToString(
        rawViz._node.firstChild
      )
      var DOMURL = window.URL || window.webkitURL || window
      var svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
      var url = DOMURL.createObjectURL(svg)
      var canvas = document.createElement('canvas')
      canvas.height = rawViz._node.firstChild.clientHeight
      canvas.width = rawViz._node.firstChild.clientWidth
      var ctx = canvas.getContext('2d')
      var img = new Image()
      img.onload = function () {
        ctx.drawImage(img, 0, 0)
        var dataUrl = canvas.toDataURL(format)
        const req = {
          "insighter_id": values?.insighter_id,
          "base64_image": dataUrl
        };
        axios.post('https://partners.myinsighta.com/api/v1/base64/decode', req)
          .then(response => setImageUrl(response))
          .then(()=> {
            setLoading(false)
            setShowModal(true)
          })
          .catch((error)=> {
            setLoading(false)
            setShowErrorModal(true)
            // setHasError(error.message)
          });
        //downloadBlob(dataUrl, filename)
        DOMURL.revokeObjectURL(svg)
      }
      img.src = url
    },
    [rawViz]
  )

  const downloadProject = useCallback(
    async (filename) => {
      const project = await exportProject()
      const str = JSON.stringify(project)
      const blob = new Blob([str], { type: 'application/json' })
      const DOMURL = window.URL || window.webkitURL || window
      const url = DOMURL.createObjectURL(blob)
      downloadBlob(url, filename)
      DOMURL.revokeObjectURL(url)
    },
    [exportProject]
  )

  const exportFormats = ['svg', 'png', 'jpg', 'rawgraphs']

  const [currentFormat, setCurrentFormat] = useState('svg')
  const [currentFile, setCurrentFile] = useState('viz')

  const downloadViz = useCallback(() => {

    switch (currentFormat) {
      case 'svg':
        downloadSvg(`${currentFile}.svg`)
        break
      case 'png':
        downloadImage('image/png', `${currentFile}.png`)
        break
      case 'jpg':

        // POST request using axios inside useEffect React hook
        // const req = {
        //   "insighter_id": "4",
        //   "base64_image": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII="
        // };
        // axios.post('http://127.0.0.1:8000/api/v1/base64/decode', req)
        //   .then(response => setImageUrl(response));
        // empty dependency array means this effect will only run once (like componentDidMount in classes)
        //localStorage.setItem("images",{url:"https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg"})
        //console.log({currentFile});
        //console.log("downloadImage(image/jpeg, ${currentFile}.jpg", downloadImage('image/jpeg', `${currentFile}.jpg`)

        downloadImage('image/jpeg', `${currentFile}.jpg`)
        break
      case 'rawgraphs':
        downloadProject(`${currentFile}.rawgraphs`)
        break
      default:
        break
    }
    // TODO: Make a getter for _chartImplementation
    onChartExported(rawViz._chartImplementation.metadata, currentFormat)
  }, [
    currentFile,
    currentFormat,
    downloadImage,
    downloadProject,
    downloadSvg,
    rawViz,
  ])
// console.log(loaging);
  return (
    <div className="row">

      <div className="col col-sm-3">
        <InputGroup className="mb-3 raw-input-group">
          <input
            type="text"
            className="form-control text-field"
            value={currentFile}
            onChange={(e) => setCurrentFile(e.target.value)}
          ></input>
          <DropdownButton
            as={InputGroup.Append}
            title={`.${currentFormat}`}
            id="input-group-dropdown-1"
            className="raw-dropdown"
          >
            {exportFormats.map((d) => {
              return (
                <Dropdown.Item key={d} onClick={() => setCurrentFormat(d)}>
                  .{d}
                </Dropdown.Item>
              )
            })}
          </DropdownButton>
        </InputGroup>
      </div>

      <div className="col col-sm-2">
        <button
          className="btn btn-primary btn-block raw-btn"
          onClick={downloadViz}
          
        >
          Download
        </button>
      </div>
      <div className="col col-sm-2">
        { loading ? (<Spinner animation="border" variant="primary" style={{width:'2rem', height:'2rem',borderWidth:'2px',marginLeft:'2rem'}} />):
                <button
                className="btn btn-primary btn-block raw-btn"
                onClick={()=> exportImageToLibrary('image/jpeg', `${currentFile}.jpg`)}
              >
                Add to my library
              </button>} 
        <ScreenSuccess 
          showModal={showModal} 
          setShowModal={(state)=>setShowModal(state)} 
          showErrorModal={showErrorModal}
          setShowErrorModal={(state)=>setShowErrorModal(state)}  /> 
      </div>
    </div>
  )
}
